nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 0;
    font-size: 1.2rem;
    min-height: 8.9vh;
    width: 100%;
    background: linear-gradient(90deg, rgb(19, 19, 43) 0%, rgb(0, 0, 0)100%);
    color: $blue;
    z-index: 10;
 

    .nav-logo{
        justify-self: start;
        margin-left: 20px;
        width: 50px;
    }

    a {
        text-decoration: none;
        color: inherit;
        text-transform: uppercase;
    }

    .menu-btn {
        display: none;
    }

    .nav-menu {
        display: grid;
        grid-template-columns: repeat(5, auto);
        gap: 10px;
        list-style: none;
        text-align: center;
        width: 70vw;
        justify-content: end;
        margin-right: 2rem;
    }

    .nav-links {
        color: $blue;
        text-decoration: none;
        padding: 0.5rem 1rem;
        letter-spacing: 3px;
        font-size: 0.8rem;
    }

    .nav-links:hover {
        background-color: rgba($color: #979292, $alpha: 0.3);
        border-radius: 4px;
        transition: all 0.3s ease-out;
    }

    .fa-bars {
        color: white;
    }

    .nav-links-mobile {
        display: none;
    }

    .menu-icon {
        display: none;
    }

    @media screen and (max-width: 1130px) {
        .nav{
            position: relative;
        }

        .nav-menu{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 700px;
            position: absolute;
            top: 70px;
            left: -100%;
            opacity: 1;
            transition: all 0.6s ease;
        }

        .nav-menu.active {
            background: linear-gradient(90deg, rgb(19, 19, 43) 0%, rgb(0, 0, 0)100%);
            left: 0;
            opacity: 1;
            height: 150%;
            transition: all 0.5s ease;
            z-index: 6;
            position: absolute;
        }

        .nav-links{
            text-align: center;
            padding: 2rem;
            width: 100%;
            display: table ;
        }

        .nav-links:hover {
        background-color: rgba($color: #979292, $alpha: 0.3);
        border-radius: 0px;
        }

        .nav-logo {
            top: 0;
            left: auto;
            transform: translate(-150%, 5%);
            z-index: 10;
        }

        .menu-icon {
            display: block;
            top: -25;
            right: 0;
            transform: translate(125%, 5%);
            font-size: 1.8rem;
            cursor: pointer;
            z-index: 10;
        }

        .fa-bars {
            color: white;
        }

        .fa-times {
            color: $blue;
            font-size: 2rem;
        }
    }

}
