table, th, td {
    border-bottom: 1px solid black;
    border-collapse: collapse;
  }

  .wi {
    font-size: 50px;
    color: $blue;
  }

  th, td {
    padding: 8px;
  }

  th {
    text-align: left;
  }

  table {
    border-spacing: 5px;
  }

  .weather-box{
    display: flex;
    justify-content:space-around;
    align-items: center;
  }

  .weather-icons-box{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
  }

  .weather-icons-block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

  }

  .weather-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px;
  }

  .weather-icon-data {
      margin-top: 0px;
  }

  .weather-description {
      margin: 32px;
      text-align: center;
  }

  .leaflet-container {
    width: 100%;
    height: 200px; 
  }

  .launch-site {
    text-align: center;
    margin-bottom: 10px;
  }

  .launch-title {
    margin-bottom: 20px;
  }

  .map {
    margin: 10px;
  }


  @media screen and (max-width: 400px) {

  }
