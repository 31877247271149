footer {
    margin-top: auto;
    bottom: 0;

}

.footer-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
    min-height: 5.5vh;
    width: 100%;
    background: linear-gradient(90deg, rgb(19, 19, 43) 0%, rgb(0, 0, 0)100%);
    color: $blue;

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.footer-menu {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 20px;
        list-style: none;
        text-align: center;
        width: 70vw;
        justify-content: center;
        margin-bottom: 15px;
}

.footer-links {
        color: $blue;
        text-decoration: none;
        padding: 0.5rem 1rem;
        letter-spacing: 3px;
        font-size: 1.2rem;
}

.footer-links:hover {
    background-color: rgba($color: #979292, $alpha: 0.3);
    border-radius: 4px;
    transition: all 0.3s ease-out;
}

.footer-lic {
    font-size: 0.7rem;
    text-align: center;
}
}

