
.count-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.count-left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.mission-name-box {
    padding-bottom: 5px;
    border-bottom: $blue 2px solid;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.clock-box {
    justify-self: center;
    align-self: center;
    color: #353d46;
    font-size: 1.1rem;
    padding: 0.5rem 1.2rem;
    background-color: rgba($color: rgb(22, 21, 21), $alpha: 1.0);
    border: $green 3px solid;
    border-radius: 4px;
}

.clock-box-grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr); 
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    grid-column-gap: 30px;
    align-content: center;
    padding: 0px 25px;
}

.countdown-days {
    display: flex;
    flex-direction: row;
}
 
.clock-font {
    font-size: 0.8rem;
    color: rgb(139, 138, 138);
    
}

.clock-font-time {
    font-size: 1.5rem;
    color: white;
}


.count-rocket-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.rocket-img {
    margin-bottom: 20px;
    object-fit: fill;
    height: 520px;
    width: 50px;
}

.nl-details {
    font-size: 1rem;
}

.stream-box{
    justify-self: center;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
        text-align: center;
    }
}

@media screen and (max-width: 1130px) {
    .clock-box {
        font-size: .7rem;
        margin-top: 25px;
    }

    .clock-box-grid {
        grid-column-gap: 10px;
        padding: 0 10px;
    }
  }
