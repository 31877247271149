// Live Stream
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.8);
    z-index: 1000;
}  

.modal-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 0 0;
}

.modal-stlyes {
      margin-top: 20px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: (translate(-50%, -50%));
      background-color: fff;
      padding: 30px;
      z-index: 1000;
      width: 100%;
      height: 100%;
  }

  .player-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  .player-wrapper iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: (translate(-50%, -50%));
  }

  .modal-close-btn {
      margin-bottom: 10px;
  }


