.next-launch-grid {
  width: 1100px;
  margin: 30px auto;
  display: grid;
  margin: 30px auto;

  grid-template-columns: 4fr 3fr;

  gap: 20px;
  align-content: center;
  margin-bottom: 5%;
}

.main-mission-box {
  font-family: 'Exo 2', sans-serif;
    
}


.launch-site-box{

}


.mission-stats-box {
    
}

// Mission Badge
.mission-badge-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.badge-img {
  width: 40%;
}

@media screen and (max-width: 1130px) {
  .next-launch-grid{
      grid-template-columns: 2fr 2fr;
  }
}

@media screen and (max-width: 899px) {
  .next-launch-grid {
    grid-template-columns: 1fr;
    width: 100%;
    overflow: hidden;
  
  }
}

@media screen and (max-width: 500px) {
  .next-launch-grid {
    padding: 0;
  }
}