
.landing-logo {
    z-index: 0;
}

.full-screen-video-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-screen-video-container video {
    z-index: 1;
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
}

.full-screen-video-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.full-screen-video-content img {
    width: 15%;
    margin-bottom: 80px;
    opacity: 0.7;
}




@media screen and (max-width: 920px) {

    .full-screen-video-container video {
        transform: translate(-20%, -50%);
    }

    .full-screen-video-content img {
        width: 20%;
        margin-bottom: 80px;
        opacity: 0.7;
    }
    
}

@media screen and (max-width: 320px) {

    .full-screen-video-content img {
        width: 70%;
        margin-bottom: 80px;
        opacity: 0.7;
    }
    
}
