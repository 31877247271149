* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

:root {
  --primary-color: #fcfcfc80;
}

html,
body {
  background: url("../../img/spacex-6SbFGnQTE8s-unsplash.jpg");
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: scroll;
  line-height: 1.6rem;
  color: #333;
}

/* Showcase */
.showcase {
  position: relative;
  background-size: cover;
  min-height: 100vh;
  color: #fff;
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

h1,
h2 {
  font-weight: 400;
  line-height: 1.2;
  margin-top: 10px 0;
}

.title {
  font-family: "Exo 2", sans-serif;
  color: $blue;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.description-text {
  text-align: center;
}

.btn {
  border: $blue 3px solid;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: $blue;
}

button {
  background: rgba($color: #000000, $alpha: 0.6);
  border: $blue 2px solid;
  padding: 10px 30px;
  border-radius: 5px;
  text-decoration: none;
  color: $blue;
  cursor: pointer;
}

.btn-text {
  text-decoration: none;
  letter-spacing: 0.1rem;
}

.nl-details {
  color: white;
  font-size: 0.8rem;
}

.table-stat {
  color: white;
  font-size: $s-size;
}

.card {
  background: linear-gradient(
    to right bottom,
    rgba(30, 30, 66, 0.2),
    rgba(133, 129, 129, 0.1)
  );
  backdrop-filter: blur(0.4rem);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 10px;
  height: auto;
}

.header-logo {
  width: 50%;
}
