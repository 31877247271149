
.container-vehicle-grid {
    display: grid;
    max-width: 1100px;

    margin: 30px auto;

    grid-template-rows: repeat(2, 1fr); 
    grid-template-columns: 2fr 1fr;

    align-items: center;
    justify-items: stretch;
    gap: 20px;
    align-content: center;
  }


  .vechicle-info-box {      
    grid-row: 2/3;
    grid-column: 1/2;
    align-self: start;
    width: 40vw
  }

  .vechicle-description-box{
      display: flex;
      flex-direction: column;
      justify-items: center;
  }

  .vechicle-name{
      margin-bottom: 20px;
  }

  .vechicle-stats-box {
      width: 100%;
  }

  .vechicle-img-box{
    grid-row: 1/3;
    grid-column: 2/3;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .dragon-img {
    width: 100%;
    margin-bottom: 20px;
  }

  .stream-modal-box {
    display: flex;
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
  }

  .rocket-page-img {
    margin-bottom: 20px;
    object-fit: fill;
    height: 520px;
    width: auto;
  }

 

  @media screen and (max-width: 900px) {

    .container-vehicle-grid {
      grid-template-columns: 1fr;

      margin: 20px
    }

    .vechicle-stats-box {
        font-size: 0.6rem;
    }

    .stream-modal-box {
      display: flex;
      flex-direction: column;
      height: 40%;
    }

    .btn-2 {
      margin-top: 20px;
    }

    .table-stat {
        font-size: 0.6rem;
    }

    .dragon-img {
        width: 50%
    }


    .card {
      padding: 5px;
    }
  }