

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-gif {
    width: 50%;
    height: auto;
  }